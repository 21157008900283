






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
// import VueShowdown from 'vue-showdown';

// @ts-ignore
// Vue.use(VueShowdown);

@Component({
    // directives: {
    //     handleLinks: {
    //     update: (el, binding, vnode) => {
    //         setTimeout(() => {
    //         const els = el.getElementsByTagName('a');
    //         Array.from(els).forEach((el) => {
    //             el.onclick = (ev) => {
    //                 ev.preventDefault();
    //                 if (!el.href.includes('.md') && !el.href.includes('.pdf')) {
    //                     window.open(el.href);
    //                     return;
    //                 }
        
    //                 fetch(el.href)
    //                 .then((response) => response.text())
    //                 .then((result) => {
    //                     if (el.href.includes('.md')) {
    //                         // @ts-ignore
    //                         vnode.context.source = result.replaceAll('./', 'https://tondo-public-images.s3.amazonaws.com/tondo_dashboard_docs/MD+Version+of+documentation/');
    //                     }
    //                     else if (el.href.includes('.pdf')){
    //                         window.open(el.href);
    //                     }
    //                 });
    //             }; 
    //         });
    //         }, 200);
    //     },
    //     inserted: (el, binding, vnode) => {
    //         setTimeout(() => {
    //         const els = el.getElementsByTagName('a');
    //         Array.from(els).forEach((el) => {
    //             el.onclick = (ev) => {
    //                 ev.preventDefault();
    //                 if (!el.href.includes('.md') && !el.href.includes('.pdf')) {
    //                     window.open(el.href);
    //                     return;
    //                 }
        
    //                 fetch(el.href)
    //                 .then((response) => response.text())
    //                 .then((result) => {
    //                     if (el.href.includes('.md')) {
    //                         // @ts-ignore
    //                         vnode.context.source = result.replaceAll('./', 'https://tondo-public-images.s3.amazonaws.com/tondo_dashboard_docs/MD+Version+of+documentation/');
    //                     }
    //                     else if (el.href.includes('.pdf')){
    //                         window.open(el.href);
    //                     }
    //                 });
    //             }; 
    //         });
    //         }, 200);
    //     }
    //     }
    // }
})


export default class UserGuide extends Vue {
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;

    // url = 'https://tondo-public-images.s3.amazonaws.com/tondo_dashboard_docs/MD+Version+of+documentation/Tondo_Documentation.md';
    // source = '';
    // isLoading = false;

    mounted() {
        this.setPageTitle('User Guide');
        // this.loadGuides();
    }
    
    // loadGuides() {
    //     this.isLoading = true;
    //     fetch(this.url) 
    //         .then((response) => response.text())
    //         .then((result) => {
    //             // @ts-ignore
    //             this.source = result.replaceAll('./', 'https://tondo-public-images.s3.amazonaws.com/tondo_dashboard_docs/MD+Version+of+documentation/');        
    //             this.isLoading = false;
    //     });
    // }

    // backToMainPage() {
    //     fetch(this.url) 
    //         .then((response) => response.text())
    //         .then((result) => {
    //             // @ts-ignore
    //             this.source = result.replaceAll('./', 'https://tondo-public-images.s3.amazonaws.com/tondo_dashboard_docs/MD+Version+of+documentation/');        
    //             this.isLoading = false;
    //     });
    // }
}
